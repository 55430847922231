import React, { useLayoutEffect, useState } from "react";
import Banner from "./Includes/Banner";
import Navbar from "./Includes/Navbar";
import "./EmployeeTable.css";
import RsaService from "./Services/rsa.service";
import WebsitesService from "./Services/websites.service";

import AlertInfo from "./Includes/AlertInfo";

import { useFormik } from "formik";
import { registeredUsers } from "../schema";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";

export default function RegisteredUsers() {
  const [loading, setLoading] = useState(false);

  const [websites, setWebsites] = useState([]);

  const [resultData, setResultData] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [store, setStore] = useState([]);
  const [limit, setLimit] = useState("50");
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [selectedWebsite, setselectedWebsite] = useState("");

  let cmpName = `Registered Users ${
    selectedWebsite !== "" && !loading ? "[" + totalResults + "]" : ""
  }`;

  const getWebsites = async () => {
    setLoading(true);
    try {
      const response = await WebsitesService.getWebsitesListForReports();
      setWebsites(response.data.response);
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useLayoutEffect(() => {
    getWebsites();
  }, []);

  const getregisterationsByWeb = async (web, values, limit, page) => {
    setLimit(limit);
    setCurrentPage(page);
    setLoading(true);
    if (web === "") {
      setResultData([]);
      setTotalResults("0");
      setTotalPages("1");
      setDropdown([]);
      setLoading(false);
    } else {
      try {
        let response;

        if (values?.fieldtype && values?.fieldtype !== "") {
          response = await RsaService.getWebsiteRegistrationsFiltered(
            web,
            values,
            limit,
            page
          );
        } else {
          response = await RsaService.getWebsiteRegistrations(web, limit, page);
          setDropdown(
            response?.data?.data?.dropdown ? response?.data?.data?.dropdown : []
          );
          setStore(
            response?.data?.data?.stores ? response?.data?.data?.stores : []
          );
        }

        let resultData;
        resultData = response.data.data.data;
        setResultData(resultData);

        setTotalResults(response.data.data.total_records);
        setTotalPages(response.data.data.total_pages);

        setLoading(false);
      } catch (err) {
        setResultData([]);

        setTotalResults("0");
        setTotalPages("1");
        setLoading(false);
      }
    }
  };
  const today = new Date().toISOString().split("T")[0];

  const initialValues = {
    fieldtype: "",
    searchval: "",
    start: today,
    end: today,
  };
  const { values, errors, resetForm, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: registeredUsers,
      onSubmit: async (values) => {
        if (
          values.fieldtype === "date" &&
          Date.parse(values.start) > Date.parse(values.end)
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "End date must be greater than start date!",
          });
        } else {
          getregisterationsByWeb(selectedWebsite, values, limit, "1");
        }
      },
    });

  const webChange = (e) => {
    setselectedWebsite(e.target.value);
    resetForm();
    getregisterationsByWeb(e.target.value, [], limit, "1");
  };

  const Table = ({ data }) => {
    const headers = data[0] ? Object.keys(data[0]) : [];

    return (
      <>
        {data.length ? (
          <table className="styled-table mb-3 w-100">
            <thead>
              <tr>
                <th>No.</th>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    {currentPage === 1
                      ? rowIndex + 1
                      : (currentPage - 1) * limit + rowIndex + 1}
                  </td>
                  {headers.map((header, cellIndex) => (
                    <td
                      style={{ minWidth: header === "Date" ? "112px" : "auto" }}
                      key={cellIndex}
                    >
                      {item[header] || ""}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : selectedWebsite !== "" && !loading ? (
          <p className="text-center bg-danger text-white p-3 mt-3">
            No data available
          </p>
        ) : null}

        {totalResults > parseInt(limit) && totalPages > 1 ? (
          <Pagination
            activePage={parseInt(currentPage)}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getregisterationsByWeb(selectedWebsite, values, limit, e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const exportToExcel = async () => {
    if (
      values.fieldtype === "date" &&
      Date.parse(values.start) > Date.parse(values.end)
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date must be greater than start date!",
      });
    } else {
      setLoading(true);
      try {
        const response = await RsaService.getRegisteredDataForExport(
          values,
          selectedWebsite
        );
        let resultData;
        resultData = response.data.data;

        const worksheet = XLSX.utils.json_to_sheet(resultData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `${response.data.fileName}`);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No Record Found",
        });
      }
    }
  };

  return (
    <>
      <Navbar />
      <Banner cmpName={cmpName} />
      <div className="container">
        <AlertInfo />
        <form
          className="row report-get-form"
          onSubmit={handleSubmit}
          noValidate
        >
          <div className="col-lg-3 pt-3">
            <label className="title">Website</label>
            <select
              className="form-select"
              name="website"
              value={selectedWebsite || ""}
              onChange={webChange}
            >
              <option value="">Select Website</option>
              {websites.map((res) => {
                return (
                  <option key={res.id} value={res.url}>
                    {res.name}
                  </option>
                );
              })}
            </select>
          </div>
          {resultData.length || values.fieldtype !== "" ? (
            <>
              <div className="col-lg-3 pt-3">
                <div className="input-field-box mb-lg-0">
                  <label>Select Column</label>
                  <select
                    className={`form-select ${
                      errors.fieldtype && touched.fieldtype ? "is-danger" : ""
                    }`}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    name="fieldtype"
                    value={values.fieldtype || ""}
                  >
                    <option value="">Select Column</option>

                    {dropdown.map((val, index) => (
                      <option value={val.value} key={index}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.fieldtype && touched.fieldtype ? (
                  <span className="text-danger">{errors.fieldtype}</span>
                ) : null}
              </div>
              <div className="col-lg-6 ps-lg-0 pt-3">
                <div className="input-field-box mb-lg-0">
                  <label>Search</label>
                  <div className="input-group">
                    {values.fieldtype === "date" ? (
                      <>
                        <input
                          className="form-control"
                          name="start"
                          value={values.start || ""}
                          type="date"
                          onChange={handleChange}
                          max={today}
                        />
                        <input
                          className="form-control"
                          name="end"
                          value={values.end || ""}
                          type="date"
                          onChange={handleChange}
                          max={today}
                        />
                      </>
                    ) : values.fieldtype === "store_location" &&
                      store.length ? (
                      <select
                        className={`form-select ${
                          errors.searchval && touched.searchval
                            ? "is-danger"
                            : ""
                        }`}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        name="searchval"
                        value={values.searchval || ""}
                      >
                        <option value="">Select Location</option>

                        {store.map((val, index) => (
                          <option value={val.value} key={index}>
                            {val.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        className={`form-control ${
                          errors.searchval && touched.searchval
                            ? "is-danger"
                            : ""
                        }`}
                        onChange={handleChange}
                        name="searchval"
                        value={values.searchval || ""}
                        required
                      />
                    )}

                    <button className="btn-get mt-0" type="submit">
                      Search
                    </button>
                    <button
                      className="btn-get bg-danger mt-0"
                      type="reset"
                      onClick={() => window.location.reload(false)}
                    >
                      Reset
                    </button>

                    <button
                      className="btn-get export mt-0"
                      type="button"
                      onClick={exportToExcel}
                    >
                      Export
                    </button>
                  </div>
                </div>
                {errors.searchval && touched.searchval ? (
                  <span className="text-danger">{errors.searchval}</span>
                ) : errors.start && touched.start ? (
                  <span className="text-danger">{errors.start}</span>
                ) : errors.end && touched.end ? (
                  <span className="text-danger">{errors.end}</span>
                ) : null}
              </div>

              {/* <div className="col-lg-3 pt-3">
                <label className="title">Start Date</label>
                <input
                  class="form-control"
                  name="start"
                  value={values.start || ""}
                  type="date"
                  onChange={handleChange}
                  max={today}
                />

                {errors.start && touched.start ? (
                  <p className="text-danger">{errors.start}</p>
                ) : null}
              </div>
              <div className="col-lg-3 pt-3">
                <label className="title">End Date</label>
                <input
                  class="form-control"
                  name="end"
                  value={values.end || ""}
                  type="date"
                  onChange={handleChange}
                  max={today}
                />

                {errors.end && touched.end ? (
                  <p className="text-danger">{errors.end}</p>
                ) : null}
              </div>
              <div className="col-lg-3 pt-3">
                <input type="submit" value="Search" className="btn-get" />
                {values.website !== "" &&
                values.start !== "" &&
                values.end !== "" ? (
                  Date.parse(values.start) <= Date.parse(values.end) ? (
                    <button
                      className="btn-get export"
                      type="button"
                      onClick={exportToExcel}
                    >
                      Export
                    </button>
                  ) : null
                ) : null}
              </div> */}
            </>
          ) : null}
        </form>
        <div className="control-flow">
          <Table data={resultData} />
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <img
          src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1692705802/loading_hosqnr.gif"
          alt="loading"
        />
      </div>
    </>
  );
}
