import React, { useLayoutEffect, useRef, useState } from "react";
import Banner from "./Includes/Banner";
import Navbar from "./Includes/Navbar";
import "./EmployeeTable.css";
import RsaService from "./Services/rsa.service";
import WebsitesService from "./Services/websites.service";
import StoreService from "./Services/store.service";
import AlertInfo from "./Includes/AlertInfo";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function OnbeTable() {
  const [loading, setLoading] = useState(false);
  const [websites, setWebsites] = useState([]);
  const [store, setStore] = useState([]);
  const [resultData, setResultData] = useState([]);
  //   const [selectedWebsite, setSelectedWebsite] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const selectedWebsite = useRef("");
  let cmpName = "List Of ONBE Sites";

  const getData = async () => {
    // console.log(selectedWebsite);
    setLoading(true);
    try {
      const response = await StoreService.getOnbeList();

      let resultData;
      resultData = response.data.response;
      setResultData(resultData);
      setLoading(false);
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  const deleteStore = async (id) => {
    try {
      setLoading(true);
      await StoreService.changeOnbe(id);

      getData();

      setLoading(false);
      Swal.fire({
        title: "Success",
        text: "ONBE Status has been changed successfully",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#28a745",
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Error in changing ONBE Status",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#28a745",
      });
    }
  };
  return (
    <>
      <Navbar />
      <Banner cmpName={cmpName} />
      <div className="container">
        <div className="row">
          <table className="styled-table mb-5">
            <thead>
              <tr>
                <th>No.</th>
                <th>Site Name</th>
                <th>Site Url</th>
                <th>ONBE Status</th>
              </tr>
            </thead>
            <tbody>
              {resultData.length ? (
                resultData.map((res, index) => {
                  let visible = index % 2 == 0 ? "active-row" : "";

                  return (
                    <tr key={res.id} className={visible}>
                      <td>{index + 1}</td>
                      <td>{res.name}</td>
                      <td>{res.url}</td>
                      <td>
                        <label className="switch switch-green">
                          <input
                            type="checkbox"
                            className="switch-input"
                            defaultChecked={
                              res.onbe_status === "on" ? true : false
                            }
                            onChange={() => {
                              deleteStore(res.id);
                            }}
                          />
                          <span
                            className="switch-label"
                            data-on="On"
                            data-off="Off"
                          ></span>
                          <span className="switch-handle"></span>
                        </label>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <img
          src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1692705802/loading_hosqnr.gif"
          alt="loading"
        />
      </div>
    </>
  );
}
