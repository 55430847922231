import React, { useState } from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import EliteLogo from "../Includes/Images/elitesyc.jpg";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../Redux";

export default function Navbar() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const navigate = useNavigate();

  const state = useSelector((state) => state.stateVals);
  const { role } = state;
  let link = role === "cards" ? "/list-cards" : "/rsa";

  const logOut = async (e) => {
    e.preventDefault();
    setLoading(true);
    userActions.logOut(null);
    setLoading(false);
    navigate("/");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <Link className="navbar-brand" to={link}>
        <img src={EliteLogo} className="eliteLogo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav me-auto">
          {role == "admin" && (
            <>
              <li className="nav-item active">
                <Link className="nav-link" to="/rsa">
                  Home <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/list-employee">
                  Employee
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/reports">
                  Reports
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/list-onbe">
                  ONBE
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/registered-users">
                  Registrations
                </Link>
              </li>

              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="reportsNav"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  Reports
                </a>
                <div className="dropdown-menu" aria-labelledby="reportsNav">
                  <Link className="dropdown-item" to="/reports">
                    Registration
                  </Link>
                  <Link className="dropdown-item" to="/rsa-total">
                    RSA Total
                  </Link>
                </div>
              </li> */}
              {/* <li className="nav-item store">
            <Link className="nav-link" to="/store">
              Store
            </Link>
          </li> */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  Stores
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/store">
                    Add Store
                  </Link>
                  <Link className="dropdown-item" to="/list-store">
                    View Store
                  </Link>
                </div>
              </li>
            </>
          )}
          {/* FIXME general function includes all listing based on role */}
          {/* FIXME on page refresh latest navbar displays */}
          {/* {role == "cards" && (
            <li className="nav-item">
              <Link className="nav-link" to="/list-cards">
                Cards
              </Link>
            </li>
          )} */}
        </ul>

        <Link
          className="logout-btn"
          to="/"
          onClick={(e) => {
            logOut(e);
          }}
        >
          <i className="bi bi-box-arrow-right"></i> Logout
        </Link>
        <br id="space" />
      </div>
    </nav>
  );
}
