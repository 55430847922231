import React from "react";
import "./Banner.css";
export default function Banner(props) {
  return (
    <div className="left-content banner">
      <div className="overlay-left banner"></div>
      <div className="w3ls-content">
        {/* <h1>
          <span className="fa fa-magic"></span>Need further assistance?
        </h1> */}

        {
          <h2>
            {props.cmpName &&
              props.cmpName[0].toUpperCase() + props.cmpName.slice(1)}
          </h2>
        }
        {/* <a href="mailto:ashley@eliterewards.biz" className="button-w3ls">
          <span className="fa fa-long-arrow-right"></span>
          <span className="fa fa-envelope" aria-hidden="true"></span>
        </a> */}
      </div>
    </div>
  );
}
