import React, { useLayoutEffect, useRef, useState } from "react";
import Banner from "./Includes/Banner";
import Navbar from "./Includes/Navbar";
import "./EmployeeTable.css";
import RsaService from "./Services/rsa.service";
import WebsitesService from "./Services/websites.service";
import StoreService from "./Services/store.service";
import AlertInfo from "./Includes/AlertInfo";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function EmployeeTable() {
  const [loading, setLoading] = useState(false);
  const [websites, setWebsites] = useState([]);
  const [store, setStore] = useState([]);
  const [resultData, setResultData] = useState([]);
  //   const [selectedWebsite, setSelectedWebsite] = useState("");
  // const [selectedStore, setSelectedStore] = useState("");
  const [reason, setReason] = useState("");

  const selectedWebsite = useRef("");
  const selectedStore = useRef("");
  const selectedEmploye = useRef("");
  let cmpName = "List Employee";

  const getDataByWeb = async (value) => {
    // console.log(selectedWebsite);
    setLoading(true);
    try {
      const response = await RsaService.getRsaListByWebsite(
        value,
        selectedEmploye.current.value ? selectedEmploye.current.value : ""
      );

      let resultData;
      resultData = response.data.response;
      setResultData(resultData);
      setLoading(false);
    } catch (err) {
      setResultData([]);
      setLoading(false);
    }
  };

  const changeWebsite = (e) => {
    let value = e.target.value;
    localStorage.setItem("site", value);
    // setSelectedWebsite(value);
    if (value !== "") {
      getDataByWeb(value);
      getStores(value);
    } else {
      setStore([]);
      setResultData([]);
    }
  };

  const changeStore = (e) => {
    let value = e;
    // setSelectedStore(value);
    if (value !== "") {
      getData(value, selectedWebsite.current.value);
    } else {
      getDataByWeb(selectedWebsite.current.value);
    }
  };

  const getStores = async (value) => {
    setLoading(true);
    try {
      const response = await StoreService.getStoreList(value);
      setStore(response.data.response);
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getWebsites = async () => {
    setLoading(true);
    try {
      const response = await WebsitesService.getWebsitesList();
      setWebsites(response.data.response);
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getData = async (value, selectedWebsite) => {
    // console.log(selectedWebsite);
    setLoading(true);
    try {
      const response = await RsaService.getRsaList(
        value,
        selectedWebsite,
        selectedEmploye.current.value ? selectedEmploye.current.value : ""
      );

      let resultData;
      resultData = response.data.response;
      setResultData(resultData);
      setLoading(false);
    } catch (err) {
      setResultData([]);
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    getWebsites();
    // getData();
  }, []);

  const alertDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are deleting RSA!",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#DD6B55",
      showCancelButton: true,
      focusConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRsa(id, selectedWebsite.current.value);
      }
    });
  };

  const deleteRsa = async (id, url) => {
    try {
      setLoading(true);
      await RsaService.deleteRsa(id, url);
      if (selectedStore.current.value) {
        getData(selectedStore.current.value, selectedWebsite.current.value);
      } else {
        getDataByWeb(selectedWebsite.current.value);
      }

      setLoading(false);
      Swal.fire({
        title: "Success",
        text: "RSA has been deleted successfully",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#28a745",
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Error in deleting RSA",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#28a745",
      });
    }
  };
  let searchTimer;

  return (
    <>
      <Navbar />
      <Banner cmpName={cmpName} />
      <div className="container">
        <div className="row">
          <AlertInfo />
          <div className="mt-3">
            <label className="title">Website</label>
            <select
              ref={selectedWebsite}
              name="fieldtype"
              required
              onChange={(e) => changeWebsite(e)}
            >
              <option value="">Select</option>
              {websites.map((res) => {
                return (
                  <option key={res.id} value={res.url}>
                    {res.name}
                  </option>
                );
              })}
            </select>
            <label className="title">Store</label>
            <select
              ref={selectedStore}
              name="fieldtype"
              required
              onChange={(e) => changeStore(e.target.value)}
            >
              <option value="">Select</option>
              {store.map((res) => {
                let displayVal = res.store_name
                  ? `${res.store_location} - ${res.store_name}`
                  : res.store_location;
                return (
                  <option key={res.id} value={res.id}>
                    {displayVal}
                  </option>
                );
              })}
            </select>
            <label className="title">Employee ID #</label>
            <input
              type="text"
              ref={selectedEmploye}
              placeholder=""
              onChange={() => {
                clearTimeout(searchTimer);
                searchTimer = setTimeout(async () => {
                  if (selectedStore.current.value) {
                    getData(
                      selectedStore.current.value,
                      selectedWebsite.current.value
                    );
                  } else {
                    getDataByWeb(selectedWebsite.current.value);
                  }
                }, 1000);
              }}
            />
          </div>
          <table className="styled-table mb-5">
            <thead>
              <tr>
                <th>No.</th>
                <th>Store</th>
                <th>RSA Full Name</th>
                <th>Employee ID Number</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {resultData.length ? (
                resultData.map((res, index) => {
                  let visible = index % 2 == 0 ? "active-row" : "";
                  return (
                    <tr key={res.id} className={visible}>
                      <td>{index + 1}</td>
                      <td>{res.store_location}</td>

                      <td>{`${res.fname} ${res.lname}`}</td>
                      <td>{res.employee_id}</td>
                      <td>{res.email ? res.email : "N/A"}</td>
                      <td>
                        <Link
                          className="text-bg-primary"
                          to={`/edit-rsa/${res.id}`}
                        >
                          Edit
                        </Link>{" "}
                        <button
                          href=""
                          type="button"
                          className="text-bg-danger"
                          onClick={() => alertDelete(res.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <img
          src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1692705802/loading_hosqnr.gif"
          alt="loading"
        />
      </div>
    </>
  );
}
