import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;

const getWebsitesList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "/sites/list", {
    headers: headers,
  });
};

const getRedemptionWebsitesList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "/sites/list-redemption", {
    headers: headers,
  });
};

const getWebsitesListForReports = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "/register/list", {
    headers: headers,
  });
};

const WebsitesService = {
  getWebsitesList,
  getRedemptionWebsitesList,
  getWebsitesListForReports,
};

export default WebsitesService;
