import React, { useState, useEffect } from "react";
import { rsaSchema, storeSchema } from "../schema";
import { useFormik } from "formik";
import WebsitesService from "./Services/websites.service";
import StoreService from "./Services/store.service";
import RsaService from "./Services/rsa.service";
import { toast, ToastContainer } from "react-toastify";
import Navbar from "./Includes/Navbar";

function Store() {
  const [loading, setLoading] = useState(false);
  const [empErr, setEmpErr] = useState("");
  const [rsaErr, setRsaErr] = useState("");
  const [stores, setStores] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState("");

  useEffect(() => {
    const getWebsitesList = async () => {
      const { data } = await WebsitesService.getWebsitesList();
      const { response: res } = data;
      console.log(data);
      const results = [];
      res.map((value) => {
        results.push({
          key: value.name,
          url: value.url,
          value: value.id,
        });
      });
      setWebsites([{ key: "Please Select", value: "" }, ...results]);
    };
    getWebsitesList();
  }, []);

  const changeSite = (e) => {
    let site = e.target.value;
    setStores([]);
    setSelectedUrl(site);
    const getStoresList = async () => {
      const { data } = await StoreService.getStoreList(site);
      const { response: res } = data;
      console.log(data);
      setStores(res);
    };
    if (site !== "") {
      getStoresList();
    }
  };

  const submitData = async (values, action) => {
    console.log(values);
    setLoading(true);
    setEmpErr("");
    setRsaErr("");
    try {
      const { data } = await StoreService.addStore(values, selectedUrl);
      setLoading(false);
      action.resetForm();
      toast.success("Record inserted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      if (err?.response?.status === 422) {
        if (err?.response?.data?.fname || err?.response?.data?.lname) {
          setRsaErr("RSA Full Name is required!");
        } else if (err?.response?.data?.employee_id) {
          setEmpErr(err?.response?.data?.employee_id[0]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        // TODO conditional sites
        website: "",
        store_location: "",
        regions: "",
      },
      // TODO conditional sites
      validationSchema: storeSchema,
      onSubmit: (values, action) => {
        submitData(values, action);
      },
    });

  return (
    <>
      <Navbar />
      <div className="main-w3ls">
        <div className="left-content">
          <ToastContainer />
          <div className="overlay-left"></div>
          <div className="w3ls-content">
            <h1>
              <span className="fa fa-magic"></span>Need further assistance?
            </h1>

            <h2>Email Elite Rewards.</h2>
            <a href="mailto:ashley@eliterewards.biz" className="button-w3ls">
              {/* <span className="fa fa-long-arrow-right"></span> */}
              <span className="fa fa-envelope" aria-hidden="true"></span>
            </a>
          </div>
        </div>

        <div className="right-form-agile">
          <div className="sub-main-w3">
            <h3>Update Request</h3>
            <h5>Fill out our simple form</h5>
            <p>to add or remove an RSA from your store dropdown.</p>
            <form action="" onSubmit={handleSubmit}>
              <div className="form-style-agile">
                <label>Websites</label>
                <div className="pom-agile">
                  <span className="fa fa-globe"></span>
                  <select
                    onChange={(e) => {
                      changeSite(e);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    name="website"
                    required=""
                    value={values.website || ""}
                  >
                    {websites.map((res) => {
                      return (
                        <option key={res.value} value={res.url}>
                          {res.key}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors.website && touched.website ? (
                  <p className="help is-danger">{errors.website}</p>
                ) : null}
              </div>

              <div className="form-style-agile">
                <label>Store Location</label>
                <div className="pom-agile">
                  <span className="fa fa-map-marker"></span>
                  <input
                    placeholder="Store Location"
                    name="store_location"
                    type="text"
                    value={values.store_location || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required=""
                  />
                </div>
                {errors.store_location && touched.store_location ? (
                  <p className="help is-danger">{errors.store_location}</p>
                ) : null}
                {rsaErr && rsaErr}
              </div>

              <div className="form-style-agile">
                <label>Regions</label>
                <div className="pom-agile">
                  <span className="fa fa-map-marker"></span>
                  <input
                    placeholder="Regions"
                    name="regions"
                    type="text"
                    value={values.regions || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required=""
                  />
                </div>
                {errors.regions && touched.regions ? (
                  <p className="help is-danger">{errors.regions}</p>
                ) : null}
                {rsaErr && rsaErr}
              </div>

              {/* <div className="sub-agile">
              <input type="checkbox" id="brand1" value="" />
              <label htmlFor="brand1">
                <span></span>I Accept to the Terms & Conditions
              </label>
            </div> */}
              <input type="submit" value="Submit" />
            </form>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <img
          src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1692705802/loading_hosqnr.gif"
          alt="loading"
        />
      </div>
    </>
  );
}

export default Store;
